enum FeatureFlags {
  REPLATFORM,
  COUPONS,
  HIDE_PROFILE_LINKS,
  HIDE_GENDER_LINKS,
  DIRECT_CLICKOUT,
  NEWIMAGEFORMAT,
}

export type FeatureFlagsKeys = keyof typeof FeatureFlags;
export type FeatureToggles = Record<FeatureFlagsKeys, boolean>;

export const intialFeatureToggles: FeatureToggles = {
  REPLATFORM: process.env.NEXT_PUBLIC_FT_REPLATFORM === 'true',
  COUPONS: process.env.NEXT_PUBLIC_FT_COUPONS === 'true',
  HIDE_PROFILE_LINKS: process.env.NEXT_PUBLIC_FT_HIDE_PROFILE_LINKS === 'true',
  HIDE_GENDER_LINKS: process.env.NEXT_PUBLIC_FT_HIDE_GENDER_LINKS === 'true',
  DIRECT_CLICKOUT: process.env.NEXT_PUBLIC_FT_DIRECT_CLICKOUT === 'true',
  NEWIMAGEFORMAT: process.env.NEXT_PUBLIC_FT_NEWIMAGEFORMAT === 'true',
};
